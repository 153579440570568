<button
  class="op-sidemenu--title"
  type="button"
  (click)="toggleCollapsed()"
  *ngIf="title && collapsible"
>
  <span class="icon3 icon-small"
        [ngClass]="collapsed ? 'icon-arrow-down1' : 'icon-arrow-up1'"
        aria-hidden="true">
  </span>
  {{ title }}
</button>

<div
  class="op-sidemenu--title"
  *ngIf="title && !collapsible"
>
  {{ title }}
</div>

<ul
  class="op-sidemenu--items"
  [ngClass]="{'op-sidemenu--items_collapsed' : collapsed}"
>
  <li
    class="op-sidemenu--item"
    data-qa-selector="op-sidemenu--item"
    *ngFor="let item of items"
  >
    <a
      class="op-sidemenu--item-action"
      uiSrefActive="op-sidemenu--item-action_active-child"
      uiSrefActiveEq="op-sidemenu--item-action_active"
      [attr.data-qa-selector]="'op-sidemenu--item-action--' + item.title.split(' ').join('')"
      [href]="item.href"
      [uiSref]="item.uiSref"
      [uiParams]="item.uiParams"
      *ngIf="!item.children"
    >
      <span
        *ngIf="item.icon"
        class="op-sidemenu--item-icon"
        [class]="'icon-' + item.icon"
      ></span>
      <span class="op-sidemenu--item-title">{{ item.title }}</span>
      <span class="op-bubble op-bubble_alt_highlighting" *ngIf="item.count">{{ item.count }}</span>
    </a>

    <op-sidemenu
      *ngIf="item.children && item.children.length"
      [title]="item.title"
      [items]="item.children"
      [collapsible]="item.collapsible"
    ></op-sidemenu>
  </li>
</ul>
