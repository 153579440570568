<div class="op-wp-watcher form--selected-value--container" opFocusWithin="button">
  <span class="op-wp-watcher--form form--selected-value">
    <op-principal
      [principal]="watcher"
      data-link="true"
      data-principal-type="user"
      data-size="avatar-mini"
      data-qa-selector="op-wp-watcher-name"
    ></op-principal>
  </span>
  <button
    type="button"
    class="op-link form--selected-value--remover"
    [title]="text.remove"
    (click)="remove()"
  >
    <op-icon icon-classes="icon-remove"></op-icon>
  </button>
</div>
